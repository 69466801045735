// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Breadcrumbs from '../components/breadcrumbs'
import '../components/breadcrumbs/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import InternApprenticeApplicationForm from '../components/intern-apprentice-application-form'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Participate',
  slug: '/participate',
  abstract: 'Learn',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'Participate', slug: '/participate' },
  ],
}

const internApprenticeApplicationFormRef = React.createRef()
const internApprenticeApplicationFormConf = {
  macroId:
    'AKfycbwerfQGI1Sl6BzpxXQ9AAUSjNd2iRCe7i4imIrneWcacnPRygvkJAboi7_8yge01STpqw',
  includeTitle: true,
  includeExtra: true,
  fields: {
    name: {
      title: 'Name',
      extra: 'Required',
    },
    email: {
      title: 'Email',
      extra: 'Required',
    },
    phone: {
      title: 'Phone',
      extra: 'Required',
    },
    comment: {
      title: 'Application',
      extra: 'What bug bugs thee?',
      rules: [
        {
          validator: (_, value) => {
            return Promise.resolve()
          },
        },
      ],
    },
  },
  trace: {
    success:
      'Thank you for your request. We have recieved your message, and will get back to you shortly.',
  },
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    launch: file(relativePath: { eq: "launch.jpg" }) {
      ...max900
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper pageSchema={pageSchema} {...props}>
    <div className="container">
      <Breadcrumbs breadcrumbs={pageSchema.breadcrumbs} />
      <h1>Apprentice or intern with the Aura Network</h1>
      <p>
        The Aura Network will also offer unique opportunities to apprentice or
        intern with us soon.
      </p>
      <div style={{ maxWidth: '30rem' }}>
        <InternApprenticeApplicationForm
          refs={{ formRef: internApprenticeApplicationFormRef }}
          conf={{ formConf: internApprenticeApplicationFormConf }}
        />
      </div>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
